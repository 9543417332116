.cxjc0fk{position:relative;background:white;z-index:10;overflow:scroll;height:100vh;}
.c1bbt5td{cursor:pointer;}
.hirnh2p{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;height:44px;border-bottom:1px solid #c0c0c0;margin-bottom:10px;position:-webkit-sticky;position:sticky;top:0;background-color:white;z-index:11;padding:0 10px;}.hirnh2p:after{content:'';position:absolute;height:2px;right:0;left:0;top:0;background:linear-gradient(
      to right,
      #54b5c6 60%,
      #e03f19 60%,
      #e03f19 90%,
      #ffb43e 90%
    );z-index:2;}

.m10cap5d{font-size:20px;line-height:20px;font-weight:500;margin-left:30px;padding-bottom:8px;color:#54b5c6;border-bottom:1px solid #d8d8d8;position:relative;}.m10cap5d ::after{content:'';background:url('/assets/icons/arrows/menu-blue-right.svg');display:inline-block;margin-left:16px;vertical-align:-5%;width:16px;height:16px;}
.m19y3808{padding:10px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.mb3q4p2{padding-left:30px;color:var(--mb3q4p2-0);display:block;height:36px;width:50%;font-weight:normal;}
